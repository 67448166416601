<template>
  <v-list-item class="entity-card-header">
    <v-list-item-content>
      <v-list-item-title class="text-h6 font-weight-medium" :title="title">
        {{ title }}
        <!--
        <v-btn
          v-if="dragged === null"
          @click.stop.prevent="drag"
          @mousedown.stop=""
          >Двигаем</v-btn
        >
        <v-btn v-else @click.stop.prevent="undrag" @mousedown.stop=""
          >Применить</v-btn
        >
        -->
      </v-list-item-title>
      <v-list-item-subtitle>
        <div class="d-flex align-center">
          <slot name="statusbar" />
        </div>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { ref } from '@vue/composition-api';
import { useMap } from '@/compositions/map';
import Vue from 'vue';

export default Vue.extend({
  name: 'EntityCardHeader',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  setup(props, { root }) {
    const dragged = ref(null);
    const { dragMarker, undragMarker } = useMap();

    function drag() {
      dragged.value = root.$route.params.objectId;
      dragMarker(dragged.value);
    }

    function undrag() {
      dragged.value = null;
      undragMarker();
    }
    return {
      drag,
      undrag,
      dragged
    };
  }
});
</script>

<style lang="sass" scoped>
.entity-card-header
  height: 72px
</style>
